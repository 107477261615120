import React from 'react';

interface LoaderProps {
    message?: string;
}

const Loader: React.FC<LoaderProps> = ({ message = "Loading Voice Assistant..." }) => {
    const styles = {
        widgetLoader: {
            width: '100%',
            height: '100%'
        },
        loaderContent: {
            display: 'flex',
            flexDirection: 'column' as const,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: 'white'
        },
        loaderContainer: {
            position: 'relative' as const,
            width: '120px',
            height: '120px'
        },
        line: {
            position: 'absolute' as const,
            background: 'linear-gradient(to var(--direction), rgba(52, 152, 219, 0), #3498db)',
            opacity: 0,
            transformOrigin: 'var(--origin)',
            width: '60px',
            height: '3px'
        },
        centerDot: {
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            width: '10px',
            height: '10px',
            backgroundColor: '#3498db',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: '0 0 15px rgba(52, 152, 219, 0.8)'
        },
        loaderMessage: {
            marginTop: '20px',
            fontSize: '14px',
            color: '#fff'
        },
        keyframes: `
            @keyframes lineDraw {
                0% {
                    opacity: 0;
                    transform: scaleX(0);
                }
                20% {
                    opacity: 1;
                }
                80% {
                    opacity: 1;
                    transform: scaleX(1);
                }
                100% {
                    opacity: 0;
                    transform: scaleX(0);
                }
            }
            @keyframes rotate {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
            @keyframes pulse {
                0%, 100% {
                    transform: translate(-50%, -50%) scale(0.8);
                    opacity: 0.5;
                }
                50% {
                    transform: translate(-50%, -50%) scale(1.2);
                    opacity: 1;
                }
            }
        `
    };

    const lines = [
        {
            '--direction': 'right',
            '--origin': 'left center',
            top: '50%',
            left: '0',
            transform: 'translateY(-50%) rotate(45deg) translateX(-42px)',
            animation: 'lineDraw 1.5s infinite ease-in-out'
        },
        {
            '--direction': 'left',
            '--origin': 'right center',
            top: '50%',
            right: '0',
            transform: 'translateY(-50%) rotate(-45deg) translateX(42px)',
            animation: 'lineDraw 1.5s infinite ease-in-out 0.375s'
        },
        {
            '--direction': 'right',
            '--origin': 'left center',
            bottom: '50%',
            left: '0',
            transform: 'translateY(50%) rotate(-45deg) translateX(-42px)',
            animation: 'lineDraw 1.5s infinite ease-in-out 0.75s'
        },
        {
            '--direction': 'left',
            '--origin': 'right center',
            bottom: '50%',
            right: '0',
            transform: 'translateY(50%) rotate(45deg) translateX(42px)',
            animation: 'lineDraw 1.5s infinite ease-in-out 1.125s'
        }
    ] as const;

    React.useEffect(() => {
        const styleSheet = document.createElement("style");
        styleSheet.textContent = styles.keyframes;
        document.head.appendChild(styleSheet);

        return () => {
            document.head.removeChild(styleSheet);
        };
    }, [styles.keyframes]);

    return (
        <div style={styles.widgetLoader}>
            <div style={styles.loaderContent}>
                <div style={{
                    ...styles.loaderContainer,
                    animation: 'rotate 12s linear infinite'
                }}>
                    {lines.map((lineStyle, index) => (
                        <div 
                            key={index}
                            style={{ ...styles.line, ...lineStyle } as React.CSSProperties}
                        />
                    ))}
                    <div style={{
                        ...styles.centerDot,
                        animation: 'pulse 2s infinite ease-in-out'
                    }} />
                </div>
                <div style={styles.loaderMessage}>{message}</div>
            </div>
        </div>
    );
};

export default Loader;
import React, { useState, useMemo, useEffect, memo } from 'react';
import { useSearchParams } from 'react-router-dom';
//import './CallWidgetShare.css';
import useLogRenders from '../../hooks/useLogRenders';
import VoiceChatWidget from '../VoiceChat/VoiceChatWidget';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const MemoizedVoiceChatWidget = memo(VoiceChatWidget);

const CallWidgetShare = React.memo(() => {
    useLogRenders('CallWidgetShare');
    const [callNowAgent, setCallNowAgent] = useState({});
    const [user, setUser] = useState(null);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        } else {
            setUser(null);
        }
    }, []);

    useEffect(() => {
        const agent = searchParams.get('agentType'); // Get the 'agent' query parameter
        if (agent) {
            setCallNowAgent({ name: agent, state: 'open' });
        }
    }, [searchParams]);

    const generateSessionId = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // eslint-disable-next-line no-mixed-operators
            const r = Math.random() * 16 | 0;
            // eslint-disable-next-line no-mixed-operators
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    const handleGoogleLoginSuccess = (credentialResponse) => {
        try {
            const decodedToken = jwtDecode(credentialResponse.credential);
            const sessionId = generateSessionId();
            const userDetails = {
                id: decodedToken.sub,
                email: decodedToken.email,
                name: decodedToken.name,
                givenName: decodedToken.given_name,
                familyName: decodedToken.family_name,
                picture: decodedToken.picture,
                sessionKey: sessionId,
            };
            localStorage.setItem('user', JSON.stringify(userDetails));
            setUser(userDetails);
        } catch (error) {
            console.error('Error processing login:', error);
        }
    };

    const handleGoogleLoginFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    const voiceChatProps = useMemo(() => ({
        agentName: callNowAgent.name,
        user: user,
    }), [callNowAgent.name, user]);

    return (
        <div className={`call-widget-share ${callNowAgent?.state}`}>
            {user ? (
                <>
                    <div className='gradient-background'></div>
                    <div className='transparent-div'></div>
                    <div className='voice-chat-widget-agent' id='voice-chat-widget-agent'>
                        <MemoizedVoiceChatWidget key={callNowAgent.name} {...voiceChatProps} />
                    </div>
                </>
            ) : (
                <div className="login-modal">
                    <div className="login-container">
                        <h3>Please log in to continue Voice Call</h3>
                        <GoogleLogin
                            onSuccess={handleGoogleLoginSuccess}
                            onError={handleGoogleLoginFailure}
                            text="Sign in with Google"
                        />
                    </div>
                </div>
            )}
        </div>
    );
});

export default CallWidgetShare;

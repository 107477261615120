import log from './logger';

class MessagePool {
    constructor(messages = [], changeThreshold = 15) {
      this.messages = messages;
      this.usedIndices = new Set();
      this.isFirstCall = true;
      this.changeCounter = 0;
      this.changeThreshold = changeThreshold;
      //log.debug('MessagePool created with messages:', messages);
      
      // Set initial message
      this.currentMessage = this.messages.length > 0 ? this.messages[0] : '';
    }
  
    getMessage() {
      if (this.messages.length === 0) {
        log.warn('No messages available');
        return '';
      }

      if (this.isFirstCall) {
        this.isFirstCall = false;
        log.info('Returning first message:', this.currentMessage);
        return this.currentMessage;
      }

      this.changeCounter++;
      if (this.changeCounter < this.changeThreshold) {
        return this.currentMessage;
      }

      this.changeCounter = 0;

      if (this.usedIndices.size === this.messages.length - 1) {
        this.usedIndices.clear();
      }

      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * (this.messages.length - 1)) + 1;
      } while (this.usedIndices.has(randomIndex));

      this.usedIndices.add(randomIndex);
      this.currentMessage = this.messages[randomIndex];
      log.info('Returning new random message:', this.currentMessage);
      return this.currentMessage;
    }

    resetCounter() {
      this.changeCounter = 0;
    }
}

export default MessagePool;
import React, { useEffect, useState } from 'react';
import { Clock, AlertCircle } from 'lucide-react';
import './SessionTimeoutWarning.css';

const SessionTimeoutWarning = ({ timeoutSeconds, onExtend, onReconnect }) => {
  const [remainingSeconds, setRemainingSeconds] = useState(timeoutSeconds);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (remainingSeconds <= 0) {
      setIsExpired(true);
      return;
    }

    const timer = setInterval(() => {
      setRemainingSeconds(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          setIsExpired(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [remainingSeconds]);

  return (
    <>
      <div className="timeout-overlay" />
      <div className="session-timeout-warning">
        <div className="warning-content">
          <div className={`warning-icon ${!isExpired ? 'shaking' : ''}`}>
            {isExpired ? <AlertCircle size={24} color="#ef4444" /> : <Clock size={24} />}
          </div>
          <div className="warning-text">
            <div className="warning-title">
              {isExpired ? 'Session Expired' : 'Session Timeout Warning'}
            </div>
            <p className="warning-message">
              {isExpired 
                ? 'Your session has been disconnected due to inactivity.' 
                : `Your session will expire in ${remainingSeconds} seconds. Please extend to stay connected.`
              }
            </p>
            <div className="warning-actions">
              <button
                onClick={isExpired ? onReconnect : onExtend}
                className="extend-button"
              >
                {isExpired ? 'Reconnect' : 'Stay Connected'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionTimeoutWarning;
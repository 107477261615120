import log from '../utils/logger';

// eslint-disable-next-line no-unused-vars
const generalConfig = {
    fftSize: 256,                // Size of the FFT for frequency analysis. Higher values give more detail but are computationally expensive.
    smoothingTimeConstant: 0.8,   // Controls how quickly the analyser reacts to volume changes. Higher values make it less responsive but smoother.
    minDecibels: -70,            // Minimum decibel value for the analysis. Sounds below this are ignored.
    maxDecibels: -30,             // Maximum decibel value for the analysis. Sounds above this are capped.
    silentFramesThreshold: 130,    // Number of consecutive silent frames before considering the audio as silent.
    minRecordingDuration: 1000,     // Minimum recording duration in milliseconds
    voiceActivityThreshold: 0.3,   // Add this new parameter
    debugMode: true              // When true, outputs debug information to the console.
};

// Simple config apprach
// eslint-disable-next-line no-unused-vars
const simpleThresholdConfig = {
    approach: 'simple-threshold',   // Identifies this as the simple threshold approach.
    thresholdLevel: 40,             // The volume level above which sound is considered speech. Range: 0-255.
};

// Moving Average Approach
// eslint-disable-next-line no-unused-vars
const movingAverageConfig = {
    approach: 'moving-average',     // Identifies this as the moving average approach.
    thresholdLevel: 30,             // Base level for considering a frequency as active. Lower values increase sensitivity.
    thresholdPercentage: 0.4,       // Percentage of active frequencies required to trigger voice detection.
    smoothingFactor: 0.6,           // Controls how quickly the moving average adapts. Higher values make it less responsive but more stable.
};

// Frequency Distribution Approach
// eslint-disable-next-line no-unused-vars
const frequencyDistributionConfig = {
    approach: 'frequency-distribution', // Identifies this as the frequency distribution approach.
    thresholdLevel: 130,                 // Amplitude threshold for considering a frequency as significant.
    thresholdPercentage: 0.15,          // Percentage of significant frequencies required to detect voice.
    cooldownPeriod: 1500                // cooldown period in ms
};

const mergeConfigs = (generalConfig, specificConfig) => {
    return { ...generalConfig, ...specificConfig };
};

const useVADConfig = mergeConfigs(generalConfig, frequencyDistributionConfig);


const implementCustomVAD = (stream, onVoiceStart, onVoiceStop, setDebugStats, recordingStartTimeRef) => {

    const mergedConfig = { ...useVADConfig };
    const {
        approach,
        fftSize,
        smoothingTimeConstant,
        minDecibels,
        maxDecibels,
        thresholdPercentage,
        silentFramesThreshold,
        smoothingFactor,
        debugMode,
        cooldownPeriod,
        minRecordingDuration,
        voiceActivityThreshold
    } = mergedConfig;

    let thresholdLevel = mergedConfig.thresholdLevel;
    let audioContext, analyser, source, bufferLength, dataArray;
    let voiceDetected = false;
    let silentFrames = 0;
    let lastVoiceActivityTime = Date.now(); // Initialize to current time
    let recordingStartTime = 0;
    let animationFrameId = null;
    let cooldownTimeoutId = null;

    try {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
        analyser = audioContext.createAnalyser();
        source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        analyser.fftSize = fftSize;
        analyser.smoothingTimeConstant = smoothingTimeConstant;
        analyser.minDecibels = minDecibels;
        analyser.maxDecibels = maxDecibels;

        bufferLength = analyser.frequencyBinCount;
        dataArray = new Uint8Array(bufferLength);
    } catch (error) {
        log.error('Error setting up audio context:', error);
        return null;
    }

    const applyCooldown = () => {
        if (cooldownTimeoutId) clearTimeout(cooldownTimeoutId);
        if (animationFrameId) cancelAnimationFrame(animationFrameId);
        cooldownTimeoutId = setTimeout(() => {
            animationFrameId = requestAnimationFrame(checkAudioLevel);
        }, cooldownPeriod);
    };

    let lastDebugUpdateTime = 0;
    const DEBUG_UPDATE_INTERVAL = 1000; // Update debug stats every 1 second

    const checkAudioLevel = () => {
        try {
            analyser.getByteFrequencyData(dataArray);

            let result;
            let currentValue;
            let percentageAboveThreshold;
            let significantFrequencies;
            let adjustedThreshold;

            switch (approach) {
                case 'simple-threshold':
                    currentValue = dataArray.reduce((sum, value) => sum + value, 0) / bufferLength;
                    result = simpleThresholdApproach(currentValue, thresholdLevel);
                    percentageAboveThreshold = dataArray.reduce((count, value) => count + (value > thresholdLevel ? 1 : 0), 0) / bufferLength;
                    break;
                case 'moving-average':
                    currentValue = dataArray.reduce((count, value) => count + (value > thresholdLevel ? 1 : 0), 0) / bufferLength;
                    result = movingAverageApproach(currentValue, thresholdPercentage, smoothingFactor);
                    percentageAboveThreshold = currentValue;
                    break;
                case 'frequency-distribution':
                    ({ result, currentValue, adjustedThreshold, significantFrequencies, percentageAboveThreshold } =
                        frequencyDistributionApproach(dataArray, thresholdLevel, thresholdPercentage));
                    thresholdLevel = adjustedThreshold;
                    break;
                default:
                    log.error('Unknown VAD approach');
                    return;
            }

            const currentTime = Date.now();

            if (result) {
                // Voice activity detected
                if (!voiceDetected) {
                    voiceDetected = true;
                    recordingStartTime = currentTime;
                    recordingStartTimeRef.current = recordingStartTime;
                    onVoiceStart();
                    log.info("voiceDetected start time", recordingStartTime)
                }
                silentFrames = 0;
                lastVoiceActivityTime = currentTime;
            } else {
                // No voice activity
                const silenceDuration = currentTime - lastVoiceActivityTime;
                const framesSinceLastVoice = Math.floor(silenceDuration / 16.67); // Assuming 60fps

                if (voiceDetected) {
                    silentFrames = Math.min(silentFrames + 1, framesSinceLastVoice);

                    const recordingDuration = currentTime - recordingStartTime;

                    if (silentFrames >= silentFramesThreshold &&
                        recordingDuration >= minRecordingDuration &&
                        percentageAboveThreshold < voiceActivityThreshold) {
                        voiceDetected = false;
                        onVoiceStop();
                        silentFrames = 0;
                        applyCooldown();
                        return;
                    }
                }
            }

            if (debugMode) {
                if (currentTime - lastDebugUpdateTime > DEBUG_UPDATE_INTERVAL) {
                    const stats = {
                        approach,
                        voiceDetected,
                        silentFrames,
                        currentValue,
                        result,
                        threshold: thresholdLevel,
                        percentageAboveThreshold,
                        lastVoiceActivityTime,
                        currentTime,
                        silenceDuration: currentTime - lastVoiceActivityTime,
                        recordingDuration: voiceDetected ? currentTime - recordingStartTime : 0,
                        ...(approach === 'frequency-distribution' && {
                            significantFrequencies,
                            totalFrequencies: bufferLength,
                            thresholdPercentage,
                            staticThreshold: mergedConfig.thresholdLevel,
                            dynamicThreshold: adjustedThreshold
                        })
                    };
                    setDebugStats(stats);
                    lastDebugUpdateTime = currentTime;
                }
            }

            animationFrameId = requestAnimationFrame(checkAudioLevel);
        } catch (error) {
            log.error('Error in checkAudioLevel:', error);
        }
    };

    const simpleThresholdApproach = (average, threshold) => average > threshold;

    const movingAverageApproach = (currentValue, threshold, factor) => {
        let movingAverage = 0;
        movingAverage = factor * movingAverage + (1 - factor) * currentValue;
        return movingAverage > threshold;
    };

    const adjustThreshold = (currentValue, staticThreshold) => {
        return Math.max(
            staticThreshold * 0.5,
            Math.min(
                staticThreshold * 1.5,
                thresholdLevel * 0.95 + currentValue * 0.05
            )
        );
    };

    const frequencyDistributionApproach = (data, thresholdLevel, thresholdPercentage) => {
        const currentValue = data.reduce((sum, value) => sum + value, 0) / data.length;
        const adjustedThreshold = adjustThreshold(currentValue, thresholdLevel);
        const significantFrequencies = data.reduce((count, value) => count + (value > adjustedThreshold ? 1 : 0), 0);
        const percentageAboveThreshold = significantFrequencies / data.length;
        return {
            result: percentageAboveThreshold > thresholdPercentage,
            currentValue,
            adjustedThreshold,
            significantFrequencies,
            percentageAboveThreshold
        };
    };

    checkAudioLevel();

    return {
        stop: () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
            if (cooldownTimeoutId) {
                clearTimeout(cooldownTimeoutId);
            }
            source.disconnect();
            if (audioContext) audioContext.close();
        },
        updateConfig: (newConfig) => {
            Object.assign(mergedConfig, newConfig);
            analyser.fftSize = mergedConfig.fftSize;
            analyser.smoothingTimeConstant = mergedConfig.smoothingTimeConstant;
            analyser.minDecibels = mergedConfig.minDecibels;
            analyser.maxDecibels = mergedConfig.maxDecibels;
        },
        getState: () => ({
            voiceDetected,
            silentFrames,
            approach: mergedConfig.approach
        }),
        reset: () => {
            voiceDetected = false;
            silentFrames = 0;
            thresholdLevel = mergedConfig.thresholdLevel;
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
            if (cooldownTimeoutId) {
                clearTimeout(cooldownTimeoutId);
            }
            checkAudioLevel();
        }
    };
}

export { useVADConfig, implementCustomVAD };
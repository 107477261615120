import React from 'react';
import { ChevronUp, ChevronDown, X } from 'lucide-react';
import './DebugInfo.css';

const DebugInfo = ({
  state,
  isUserTalking,
  audioContextRef,
  analyserRef,
  isMonitoringSilence,
  isListening,
  isRecording,
  isBotAudioPlaying,
  wsStatus,
  user,
  wsKey,
  playbackBeforeSend,
  setPlaybackBeforeSend,
  debugMode,
  debugStats,
  isVADStatsExpanded,
  setIsVADStatsExpanded,
  memoizedDebugStats,
  isVisible,
  onClose,
}) => {
  if (!isVisible) return null;

  return (
    <div className="debug-info visible" style={{ maxHeight: '200px', overflowY: 'auto' }}>
      <div className="debug-header" onClick={onClose}>
        <span>Debug Info</span>
        <div title="Close debug info" style={{ display: 'flex', alignItems: 'center' }}>
          <X size={16} />
        </div>
      </div>
      <p>Current state: {state}</p>
      <p>Is user talking: {isUserTalking ? 'Yes' : 'No'}</p>
      <p>Audio context state: {audioContextRef.current ? audioContextRef.current.state : 'Not initialized'}</p>
      <p>Analyser node: {analyserRef.current ? 'Created' : 'Not created'}</p>
      <p>Is monitoring silence: {isMonitoringSilence.current ? 'Yes' : 'No'}</p>
      <p>Listening: {isListening ? 'active' : 'inactive'}</p>
      <p>Recording: {isRecording ? 'active' : 'inactive'}</p>
      <p>isBotAudioPlaying: {isBotAudioPlaying}</p>
      <p>WebSocket status: {wsStatus}</p>
      <p>User email: {user?.email}</p>
      <p>WebSocket Key: {wsKey}</p>
      <p>Session Key: {user?.sessionKey}</p>
      <p>WebSocket status: {wsStatus}</p>
      <div>
        <input
          type="checkbox"
          id="playbackBeforeSend"
          checked={playbackBeforeSend}
          onChange={(e) => setPlaybackBeforeSend(e.target.checked)}
        />
        <label htmlFor="playbackBeforeSend">Playback audio before sending</label>
      </div>
      {debugMode && debugStats && (
        <div className="vad-debug-stats" style={{ marginBottom: '10px' }}>
          <div className="vad-debug-header" onClick={() => setIsVADStatsExpanded(!isVADStatsExpanded)}>
            <span>Voice Activity Detection (VAD) Stats</span>
            <span>{isVADStatsExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}</span>
          </div>
          {isVADStatsExpanded && memoizedDebugStats && (
            <pre className="vad-debug-content">{JSON.stringify(memoizedDebugStats, null, 2)}</pre>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(DebugInfo);

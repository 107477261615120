import React, { useState, useCallback, useEffect, useRef } from 'react';
import BotAvatar from '../VoiceChat/BotAvatar';
import UserAvatar from '../VoiceChat/UserAvatar';
import { Send, Mic } from 'lucide-react';
import { format } from 'date-fns';
import './TextChat.css';
import '../CallTranscript/CallTranscript.css';

const TextChatSection = ({ 
  user, 
  wsStatus, 
  sendMessage, 
  setChatMode,
  messages: historyMessages,
  setMessages: setHistoryMessages
}) => {
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [localMessages, setLocalMessages] = useState([]);

  // Define messages and setMessages before using them
  const messages = historyMessages || localMessages;
  const setMessages = setHistoryMessages || setLocalMessages;

  // Add ref for messages container
  const messagesEndRef = useRef(null);

  // Scroll to bottom function
  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "instant" });
  }, []);

  // Combined useEffect for scrolling and message handling
  useEffect(() => {
    console.log('useEffect called');
    if (historyMessages?.length > 0) {
      setLocalMessages(historyMessages);
    }
    // Check if the latest message is an error
    const latestMessage = messages[messages.length - 1];
    if (latestMessage?.role === 'bot') {
      setIsTyping(false);
    }
    scrollToBottom();
  }, [historyMessages, messages, scrollToBottom]);

  const handleSendMessage = useCallback((e) => {
    e.preventDefault();
    if (inputMessage.trim() && wsStatus === 'connected') {
      setIsTyping(true);
      const newMessage = {
        role: 'user',
        content: inputMessage,
        timestamp: new Date(),
      };
      setMessages(prev => [...prev, newMessage]);
      sendMessage({
        contentType: 'text',
        content: inputMessage,
      });
      setInputMessage('');
    } else if (wsStatus !== 'connected') {
      setIsTyping(false);
    }
  }, [inputMessage, wsStatus, sendMessage, setMessages]);

  return (
    <>
      <div className="messages-container">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role} ${message.isError ? 'error' : ''}`}>
            <div className="message-avatar">
              {message.role === 'bot' ? <BotAvatar /> : <UserAvatar user={user} />}
            </div>
            <div className={`message-content ${message.isError ? 'error' : ''}`}>
              <div className="message-text">
                {message.content}
                
                {/* Audio player section */}
                {(message.audioFile || message.audioFiles) && (
                  <div className="audio-container">
                    {message.audioFile && (
                      <audio 
                        controls 
                        className="audio-player"
                      >
                        <source 
                          src={`/api/audio/${message.audioFile}`} 
                          type="audio/webm" 
                        />
                        Your browser does not support the audio element.
                      </audio>
                    )}
                    {message.audioFiles?.map((audioFile, idx) => (
                      <audio 
                        key={idx}
                        controls 
                        className="audio-player"
                      >
                        <source 
                          src={`/api/audio/${audioFile}`} 
                          type="audio/webm" 
                        />
                        Your browser does not support the audio element.
                      </audio>
                    ))}
                  </div>
                )}
              </div>
              <div className="message-time">
                {format(message.timestamp, 'hh:mm a')}
              </div>
            </div>
          </div>
        ))}
        {isTyping && (
          <div className="typing-indicator message bot d-flex-c">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        )}
        {/* Add div for scrolling reference */}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleSendMessage} className="text-input-container">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type your message..."
          disabled={wsStatus !== 'connected'}
        />
        
        <button className="send-button"
          type="submit" 
          disabled={!inputMessage.trim() || wsStatus !== 'connected'}
        >
          <Send size={18} />
        </button>
        <button
            className="mode-button"
            onClick={() => setChatMode('voice')}
            title="Voice Chat"
          >
          <Mic size={16} />
        </button>
      </form>
    </>
  );
};

export default TextChatSection;

import React, { useState, useMemo, useEffect, memo } from 'react';
import { useSearchParams } from 'react-router-dom';
import './CallPage.css';
import useLogRenders from '../../hooks/useLogRenders';
import VoiceChatWidget from '../VoiceChat/VoiceChatWidget';
//import agentCardsData from '../../agentCards.json';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const MemoizedVoiceChatWidget = memo(VoiceChatWidget);

const CallPage = React.memo(() => {
    useLogRenders('CallPage');
    const [callNowAgent, setCallNowAgent] = useState({});
    const [user, setUser] = useState(null);
    const [isInIframe, setIsInIframe] = useState(false);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        } else {
            setUser(null);
        }
    }, []);

    useEffect(() => {
        const agent = searchParams.get('agent'); // Get the 'agent' query parameter
        if (agent) {
            setCallNowAgent({ name: agent, state: 'open' });
        }
    }, [searchParams]);

    useEffect(() => {
        const checkIfInIframe = () => {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        };
        setIsInIframe(checkIfInIframe());
    }, []);

    const generateSessionId = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // eslint-disable-next-line no-mixed-operators
            const r = Math.random() * 16 | 0;
            // eslint-disable-next-line no-mixed-operators
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    const handleGoogleLoginSuccess = (credentialResponse) => {
        try {
            const decodedToken = jwtDecode(credentialResponse.credential);
            const sessionId = generateSessionId();
            const userDetails = {
                id: decodedToken.sub,
                email: decodedToken.email,
                name: decodedToken.name,
                givenName: decodedToken.given_name,
                familyName: decodedToken.family_name,
                picture: decodedToken.picture,
                sessionKey: sessionId,
            };
            localStorage.setItem('user', JSON.stringify(userDetails));
            setUser(userDetails);
        } catch (error) {
            console.error('Error processing login:', error);
        }
    };

    const handleGoogleLoginFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    const voiceChatProps = useMemo(() => ({
        agentName: callNowAgent.name,
        user: user,
    }), [callNowAgent.name, user]);

    const handleSession = (userInfo) => {
        console.log("handleSession", userInfo);
        if (userInfo) {
            setUser(userInfo);
        } else {
            setUser(null); // Clear the user session if null is passed
        }
    };

    return (
        <div className={`call-page ${callNowAgent?.state} w-100 h-100 ${!isInIframe ? 'browser-view' : 'iframe-view'}`}>
            {user ? (
                <>
                    <div className='gradient-background'></div>
                    <div className='transparent-div'></div>
                    <MemoizedVoiceChatWidget key={callNowAgent.name} {...voiceChatProps} handleSession={handleSession} />
                </>
            ) : (
                <div className="login-modal">
                    <div className="login-container">
                        <h3>Please log in to continue Voice Call</h3>
                        <GoogleLogin
                            onSuccess={handleGoogleLoginSuccess}
                            onError={handleGoogleLoginFailure}
                            text="Sign in with Google"
                        />
                    </div>
                </div>
            )}
        </div>
    )
});

export default CallPage;
export const convertAudioToWav = async (webmBlob, audioContext) => {
    try {
        // Read the Blob as an ArrayBuffer
        console.log('Starting conversion for Blob:', { size: webmBlob.size, type: webmBlob.type });
        const arrayBuffer = await webmBlob.arrayBuffer();
        console.log('ArrayBuffer obtained:', { byteLength: arrayBuffer.byteLength });

        if (arrayBuffer.byteLength === 0) {
            throw new Error('ArrayBuffer is empty');
        }
        // Create an AudioContext
        // const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        // Decode the audio data
        if (!audioContext) {
            throw new Error('AudioContext is not provided');
        }
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        console.log('Audio data decoded successfully');
        // Convert to WAV format
        const wavBuffer = encodeWAV(audioBuffer);

        // Create a new Blob from the WAV buffer
        const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });

        return wavBlob;
    } catch (error) {
        console.error('Error in convertAudioToWav:', error);
        throw error;
    }
};

/**
 * Encodes an AudioBuffer into WAV format.
 * @param {AudioBuffer} audioBuffer - The audio buffer to encode.
 * @returns {ArrayBuffer} - The encoded WAV data.
 */
const encodeWAV = (audioBuffer) => {
    const numChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const format = 1; // PCM
    const bitDepth = 16;

    const samples = audioBuffer.length * numChannels;
    const buffer = new ArrayBuffer(44 + samples * 2);
    const view = new DataView(buffer);

    /* RIFF identifier */
    writeString(view, 0, 'RIFF');
    /* file length */
    view.setUint32(4, 36 + samples * 2, true);
    /* RIFF type */
    writeString(view, 8, 'WAVE');
    /* format chunk identifier */
    writeString(view, 12, 'fmt ');
    /* format chunk length */
    view.setUint32(16, 16, true);
    /* sample format (raw) */
    view.setUint16(20, format, true);
    /* channel count */
    view.setUint16(22, numChannels, true);
    /* sample rate */
    view.setUint32(24, sampleRate, true);
    /* byte rate (sample rate * block align) */
    view.setUint32(28, sampleRate * numChannels * (bitDepth / 8), true);
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, numChannels * (bitDepth / 8), true);
    /* bits per sample */
    view.setUint16(34, bitDepth, true);
    /* data chunk identifier */
    writeString(view, 36, 'data');
    /* data chunk length */
    view.setUint32(40, samples * 2, true);

    // Write the PCM samples
    let offset = 44;
    for (let i = 0; i < audioBuffer.length; i++) {
        for (let channel = 0; channel < numChannels; channel++) {
            let sample = audioBuffer.getChannelData(channel)[i];
            // Clamp the sample to [-1, 1]
            sample = Math.max(-1, Math.min(1, sample));
            // Scale to 16-bit integer
            sample = sample < 0 ? sample * 0x8000 : sample * 0x7FFF;
            view.setInt16(offset, sample, true);
            offset += 2;
        }
    }

    return buffer;
};

/**
 * Writes a string to the DataView at the specified offset.
 * @param {DataView} view - The DataView to write to.
 * @param {number} offset - The byte offset to start writing at.
 * @param {string} string - The string to write.
 */
const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
};

export const combineAudioChunks = (_audioChunks) => {
    // Verify that _audioChunks is an array
    if (!Array.isArray(_audioChunks)) {
        console.error('combineAudioChunks: _audioChunks is not an array:', _audioChunks);
        throw new TypeError('_audioChunks must be an array of Blob objects.');
    }

    const combinedBlob = new Blob(_audioChunks, { type: 'audio/webm; codecs=opus' });

    console.log(`Combined Blob: Type=${combinedBlob.type}, Size=${combinedBlob.size} bytes`);

    return combinedBlob;
};

export const playAudioBlob = (blob) => {
    return new Promise((resolve, reject) => {
        const url = URL.createObjectURL(blob);
        const audio = new Audio(url);
        audio.onended = () => {
            URL.revokeObjectURL(url);
            resolve();
        };
        audio.onerror = (error) => {
            URL.revokeObjectURL(url);
            reject(error);
        };
        audio.play().catch(reject);
    });
};

export const convertToAudioBuffer = async (bufferedChunks, audioContextRef) => {
    try {

        if (!audioContextRef.current) {
            throw new Error('AudioContext is not initialized');
        }

        // Combine the relevant blobs into a single Blob
        const combinedBlob = new Blob(bufferedChunks.map((chunk) => chunk.blob), {
            type: 'audio/webm',
        });

        const arrayBuffer = await combinedBlob.arrayBuffer();
        const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);

        return audioBuffer;
    } catch (error) {
        console.error('Error in convertAudioToWav:', error);
        throw error;
    }
};

/**
 * Trims an AudioBuffer from startTime to endTime.
 * If endTime is null, trims from startTime to the end of the buffer.
 *
 * @param {AudioBuffer} audioBuffer - The original AudioBuffer.
 * @param {number} startTime - The start time in milliseconds.
 * @param {number|null} endTime - The end time in milliseconds. If null, trims to the end.
 * @returns {AudioBuffer} - The trimmed AudioBuffer.
 */
export const trimAudioBuffer = (audioContextRef, audioBuffer, startTime, endTime = null) => {
    const sampleRate = audioBuffer.sampleRate;
    const totalDuration = audioBuffer.duration * 1000; // Convert to milliseconds

    console.log('Audio Buffer Duration (ms):', totalDuration);
    console.log('Start Time (ms):', startTime);
    console.log('End Time (ms):', endTime);


    // Ensure startTime is within bounds
    if (startTime < 0) {
        console.warn(`startTime (${startTime}ms) is out of bounds. Setting startTime to 0.`);
        startTime = 0;
    }

    // If endTime is null or exceeds the total duration, set it to the end of the buffer
    if (endTime === null || endTime > totalDuration) {
        endTime = totalDuration;
    }

    // Ensure endTime is after startTime
    if (endTime < startTime) {
        throw new RangeError(`endTime (${endTime}ms) must be greater than startTime (${startTime}ms).`);
    }

    const startSample = Math.floor((startTime / 1000) * sampleRate);
    const endSample = Math.floor((endTime / 1000) * sampleRate);
    const length = endSample - startSample;
    //const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    // Create a new AudioBuffer for the trimmed audio
    const trimmedBuffer = audioContextRef.current.createBuffer(
        audioBuffer.numberOfChannels,
        length,
        sampleRate
    );

    // Copy the relevant channel data
    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
        const channelData = audioBuffer.getChannelData(channel).slice(startSample, endSample);
        trimmedBuffer.copyToChannel(channelData, channel, 0);
    }
    const wavBuffer = encodeWAV(trimmedBuffer);
    const trimmedWavBlob = new Blob([wavBuffer], { type: 'audio/wav' });

    return trimmedWavBlob;
};

export const calculateRelativeTimes = (baseTimestamp, absoluteStartTime, absoluteEndTime) => {
    if (!baseTimestamp) {
        throw new Error('Base timestamp is not set.');
    }

    const relativeStartTime = absoluteStartTime - baseTimestamp;
    const relativeEndTime = absoluteEndTime - baseTimestamp;

    return { relativeStartTime, relativeEndTime };
};

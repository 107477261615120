import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { initializeWebSocket } from '../utils/websocket';
import { debounce } from '../utils/debounce';
import log from '../utils/logger';

const MAX_RETRY_COUNT = 5;
const RECONNECTION_DELAY = 5000; // 5 seconds
const COOLDOWN_PERIOD = 60000; // 1 minute

const useWebSocket = (user, agentName, handleWebSocketMessage, setDomainStatus) => {
    const [wsStatus, setWsStatus] = useState('disconnected');
    const [wsReady, setWsReady] = useState(false);
    const [wsKey, setWsKey] = useState(null);
    const websocket = useRef(null);
    const isWsInitialized = useRef(false);
    const isUnmounted = useRef(false);
    const userRef = useRef(user);
    const agentNameRef = useRef(agentName);
    const handleWebSocketMessageRef = useRef(handleWebSocketMessage);
    const retryCountRef = useRef(0);
    const sendUserInfoRef = useRef(null);
    const getConversationHistoryRef = useRef(null);
    const debouncedConnectWebSocketRef = useRef(null);

    // Update refs when props change
    useEffect(() => {
        userRef.current = user;
        agentNameRef.current = agentName;
        handleWebSocketMessageRef.current = handleWebSocketMessage;
    }, [user, agentName, handleWebSocketMessage]);

    const handleWebSocketError = useCallback((event) => {
        log.error('WebSocket connection error:', event);
        setWsStatus('disconnected');
        setWsReady(false);
    }, []);

    const handleWebSocketClose = useCallback((event) => {
        setWsStatus('disconnected');
        setWsReady(false);
        isWsInitialized.current = false;
        if (websocket.current) {
            websocket.current.close();
            websocket.current = null;
        }
    }, []);

    const closeExistingConnection = useCallback(() => {
        if (websocket.current) {
            console.log('Closing existing WebSocket connection');
            websocket.current.close();
            websocket.current = null;
        }
        clearTimeout(window.wsReconnectTimeout);
    }, []);

    const scheduleReconnection = useCallback(() => {
        if (retryCountRef.current < MAX_RETRY_COUNT) {
            console.log(`Scheduling WebSocket reconnection attempt ${retryCountRef.current + 1}/${MAX_RETRY_COUNT}`);
            clearTimeout(window.wsReconnectTimeout);
            window.wsReconnectTimeout = setTimeout(() => {
                if (isUnmounted.current) {
                    console.log('Component unmounted, skipping reconnection');
                    return;
                }
                console.log(`Attempting to reconnect WebSocket... (${retryCountRef.current + 1}/${MAX_RETRY_COUNT})`);
                retryCountRef.current += 1;
                if (debouncedConnectWebSocketRef.current) {
                    debouncedConnectWebSocketRef.current();
                }
            }, RECONNECTION_DELAY);
        } else {
            console.log('Max reconnection attempts reached. Resetting retry count.');
            retryCountRef.current = 0; // Reset the retry count
            // Optionally, you can add a longer delay before allowing reconnection attempts
            setTimeout(() => {
                console.log('Reconnection attempts allowed again after cooldown period.');
            }, COOLDOWN_PERIOD);
        }
    }, []);

    const connectWebSocket = useCallback(() => {
        console.log('connectWebSocket called');
        closeExistingConnection();
        try {
            console.log('Debounced connectWebSocket called');
            if (websocket.current) {
                console.log('Closing existing WebSocket connection');
                websocket.current.close();
                websocket.current = null;
            }

            setWsStatus('connecting');
            console.log('Attempting to connect WebSocket...');

            websocket.current = initializeWebSocket({
                onMessage: (message) => {
                    console.log('WebSocket message received', message);
                    // Parse the message if it's a string
                    const parsedMessage = typeof message === 'string' ? JSON.parse(message) : message;
                    
                    // Check domain status first
                    if (parsedMessage.type === 'domain_error' || parsedMessage.type === 'domain_allowed' || parsedMessage.type === 'subscription_error') {
                        setDomainStatus(parsedMessage.type);
                        if (parsedMessage.type === 'domain_allowed' && getConversationHistoryRef.current) {
                            agentNameRef.current = parsedMessage.agentType;
                            getConversationHistoryRef.current();
                        }
                        return;
                    }
                    // Handle other message types
                    handleWebSocketMessageRef.current(message);
                },
                onError: (error) => {
                    log.error('WebSocket error in hook:', error);
                    setDomainStatus('socket_error');
                    handleWebSocketError(error);
                },
                onOpen: () => {
                    console.log('WebSocket connection established in hook');
                    retryCountRef.current = 0; // Reset retry count on successful connection
                    setWsKey(`ws_${Date.now()}`);
                    setWsStatus('connected');
                    setWsReady(true);
                    isWsInitialized.current = true;
                    
                    console.log('sendUserInfoRef.current @ connectWebSocket');
                    // Send user info after successful connection
                    if (sendUserInfoRef.current) {
                        sendUserInfoRef.current();
                    }
                },
                onClose: (event) => {
                    if (event === undefined) {
                        setTimeout(() => {
                            if (!isUnmounted.current) {
                                console.log('Attempting to reconnect after error');
                                connectWebSocket();
                            }
                        }, 5000); // 5 second delay before reconnecting
                    } else {
                        console.log('WebSocket closed in hook:', event);
                        handleWebSocketClose(event);
                        setWsReady(false);

                        if (event.code !== 1000) {
                            scheduleReconnection();
                        }
                    }
                },
                isUnmounted,
            });

            console.log('WebSocket initialization completed');
            // Add a check to ensure the WebSocket was properly initialized
            if (!websocket.current) {
                throw new Error('WebSocket failed to initialize');
            }
        } catch (error) {
            log.error('Error in connectWebSocket:', error);
            setWsStatus('error');
            setWsReady(false);
            // Attempt to reconnect after a delay
            setTimeout(() => {
                if (!isUnmounted.current) {
                    console.log('Attempting to reconnect after error');
                    connectWebSocket();
                }
            }, 5000); // 5 second delay before reconnecting
        }
    }, [handleWebSocketError, handleWebSocketClose, closeExistingConnection, scheduleReconnection, setDomainStatus]);

    debouncedConnectWebSocketRef.current = useMemo(
        () => debounce(connectWebSocket, 1000),
        [connectWebSocket]
    );

    useEffect(() => {
        console.log('useEffect in useWebSocket hook triggered');
        isUnmounted.current = false;
        //console.log('isUnmounted set to false');

        if (debouncedConnectWebSocketRef.current) {
            debouncedConnectWebSocketRef.current();
        }

        return () => {
            console.log('Cleanup function in useWebSocket hook triggered');
            if (debouncedConnectWebSocketRef.current) {
                debouncedConnectWebSocketRef.current.cancel();
            }
            closeExistingConnection();
            isUnmounted.current = true;
            console.log('isUnmounted set to true');
        };
    }, [closeExistingConnection]);

    const sendMessage = useCallback((message) => {
        if (isUnmounted.current) {
            log.warn('Attempted to send message after component unmounted:', message);
            return;
        }

        console.log('sendMessage called', message);
        if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
            if (message instanceof Blob) {
                websocket.current.send(message);
            } else {
                websocket.current.send(JSON.stringify(message));
            }
            console.log('Message sent successfully');
        } else {
            log.error('WebSocket is not open. Message not sent:', message);
            if (!websocket.current || websocket.current.readyState === WebSocket.CLOSED) {
                console.log('WebSocket is closed. Attempting to reconnect.');
                scheduleReconnection();
            } else if (websocket.current.readyState === WebSocket.CONNECTING) {
                console.log('WebSocket is still connecting. Waiting for connection.');
            } else if (websocket.current.readyState === WebSocket.CLOSING) {
                console.log('WebSocket is closing. Will attempt to reconnect once closed.');
            }
        }
    }, [scheduleReconnection]);

    // Add function to parse URL parameters
    const getUrlParams = useCallback(() => {
        const searchParams = new URLSearchParams(window.location.search);
        try {
            return {
                subscriptionId: searchParams.get('subscriptionId'),
                background: searchParams.get('background') || '#2563eb',
                foreground: searchParams.get('foreground') || '#FFFFFF',
                width: searchParams.get('width') || '350px',
                height: searchParams.get('height') || '360px'
            };
        } catch (error) {
            console.error('Error parsing URL parameters:', error);
            return {
                subscriptionId: searchParams.get('subscriptionId'),
                background: null,
                foreground: null,
                width: null,
                height: null
            };
        }
    }, []);

    sendUserInfoRef.current = useCallback(() => {
        if (websocket.current && websocket.current.readyState === WebSocket.OPEN && userRef.current) {
            const urlParams = getUrlParams();
            const userInfo = {
                type: 'user_info',
                wsKey: wsKey || `ws_${Date.now()}`,
                sessionKey: userRef.current.sessionKey,
                email: userRef.current.email,
                userId: userRef.current.id,
                //conversationType: agentNameRef.current,
                // Add only essential parameters
                subscriptionId: urlParams.subscriptionId,
                origin: window.location.origin,
                referrer: document.referrer
            };
            //console.log('sendUserInfoRef @ sendMessage', userInfo);
            sendMessage(userInfo);
            //setWsKey(userInfo.wsKey);
        }
    }, [wsKey, sendMessage, getUrlParams]);

    getConversationHistoryRef.current = useCallback(() => {
        if (websocket.current && websocket.current.readyState === WebSocket.OPEN && userRef.current) {
            const urlParams = getUrlParams();
            const historyRequest = {
                type: 'get_conversation_history',
                wsKey: wsKey || `ws_${Date.now()}`,
                sessionKey: userRef.current.sessionKey,
                email: userRef.current.email,
                userId: userRef.current.id,
                conversationType: agentNameRef.current,
                subscriptionId: urlParams.subscriptionId,
                limit: 50, // Number of messages to fetch
                offset: 0  // Starting point
            };
            //console.log('getConversationHistoryRef @ sendMessage', historyRequest);
            sendMessage(historyRequest);
        }
    }, [wsKey, sendMessage, getUrlParams]);

    return {
        wsStatus,
        wsReady,
        wsKey,
        sendMessage,
        sendUserInfo: sendUserInfoRef.current,
        getConversationHistory: getConversationHistoryRef.current,
        isReady: isWsInitialized.current,
        connectWebSocket
    };
};

export default useWebSocket;

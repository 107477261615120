import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import { Phone, X, Bot } from 'lucide-react';
import useLogRenders from '../../hooks/useLogRenders';
//import CallNowWidget from '../CallNowWidget/CallNowWidget';
import agentCardsData from '../../agentCards.json';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import BannerRotation from '../Banner/BannerRotation'; // Import the BannerRotation component

const Home = React.memo(() => {
    useLogRenders('Home');
    const [callNowAgent, setCallNowAgent] = useState({});
    const [user, setUser] = useState(null);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const generateSessionId = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          // eslint-disable-next-line no-mixed-operators
          const r = Math.random() * 16 | 0;
          // eslint-disable-next-line no-mixed-operators
          const v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
    };

    const handleGoogleLoginSuccess = (credentialResponse) => {
        try {
          const decodedToken = jwtDecode(credentialResponse.credential);
          const sessionId = generateSessionId();
          const userDetails = {
            id: decodedToken.sub,
            email: decodedToken.email,
            name: decodedToken.name,
            givenName: decodedToken.given_name,
            familyName: decodedToken.family_name,
            picture: decodedToken.picture,
            sessionKey: sessionId,
          };
          localStorage.setItem('user', JSON.stringify(userDetails));
          setUser(userDetails);
          setShowLogin(false); // Hide login after successful login
        } catch (error) {
          console.error('Error processing login:', error);
        }
    };

    const handleGoogleLoginFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    const handleCallNowClick = useCallback((agentName, subscriptionId) => {
        //console.log('Link clicked', agentName);
        if (user) {
            setCallNowAgent({ name: agentName, subscriptionId: subscriptionId, state: 'open' });
        } else {
            setShowLogin(true); // Show login if user is not logged in
            setCallNowAgent({ name: agentName, subscriptionId: subscriptionId, state: 'open' });
        }
    }, [user]);

    const handleSession = (userInfo) => {
        console.log("handleSession", userInfo);
        if (userInfo) {
            setUser(userInfo);
        } else {
            setUser(null); // Clear the user session if null is passed
        }
    };

    const closeLoginModal = () => {
        setShowLogin(false);
    };

    const getAgentImage = useMemo(() => {
        return (agentName) => require(`../../images/${agentCardsData.find((agent) => agent.name === agentName).imagePath}`);
    }, []);

    const renderCards = useMemo(() => {
        return agentCardsData.map((agent, index) => (
            <div className="card" key={index}>
                <div className="card-image-container">
                    <img src={getAgentImage(agent.name)} alt={agent.title} className="card-image" />
                </div>
                <div className="card-content">
                    <h3 className="card-title">
                        <Link target='_blank' to={`/call?agent=${agent.name}`}>{agent.title}</Link>
                    </h3>
                    <p className="card-description">{agent.description}</p>
                    <div className="w-100 d-flex-c">
                        <Link className="call-button" onClick={() => handleCallNowClick(agent.name, agent.subscriptionId)}>
                            <Phone size={14} style={{ marginRight: '0.5rem' }} />
                            Demo
                        </Link>
                        <Link className="call-button" target='_blank' to={`https://platform.rapo.ai/?subscribe=${agent.name}`}>
                            <Bot size={18} style={{ marginRight: '0.5rem' }} />
                            Hire
                        </Link>
                    </div>
                    
                </div>
            </div>
        ));
    }, [getAgentImage, handleCallNowClick]);

    useEffect(() => {
        console.log("callNowAgent", callNowAgent);
        
        // Cleanup function to remove existing widget content and script
        const cleanupWidget = () => {
            
            const container = document.getElementById('chat-widget-container');
            if (container) {
                console.log("cleanupWidget");
                // Remove all existing content
                container.innerHTML = '';
            }
             // Remove any existing widget instances and scripts
            const existingWidgets = document.querySelectorAll('[data-widget-instance]');
            existingWidgets.forEach(widget => widget.remove());
            
            // Remove any existing widget scripts
            const existingScripts = document.querySelectorAll('script[data-widget-script]');
            existingScripts.forEach(script => script.remove());
        };

        // Clean up existing content first
        cleanupWidget();

        if (callNowAgent.subscriptionId && user) {
            let timeOut = 0;
            // Show loader first
            const container = document.getElementById('chat-widget-container');
            if (container) {
                timeOut = 1500;
                // Add loader
                const loader = document.createElement('div');
                loader.className = 'widget-loader w-100 h-100 d-flex-c';
                loader.innerHTML = `
                    <div class="loader-content" style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        color: white;
                    ">
                        <div class="spinner" style="
                            width: 5px;
                            height: 5px;
                            border: 4px solid #f3f3f3;
                            border-top: 4px solid #3498db;
                            border-radius: 50%;
                            animation: spin 1s linear infinite;
                            margin-bottom: 5px;
                        "></div>
                        <div style="font-size: 14px;">Loading Agent...</div>
                    </div>
                `;

                // Add CSS animation
                const style = document.createElement('style');
                style.textContent = `
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `;
                document.head.appendChild(style);
                
                // Clear container and add loader
                container.innerHTML = '';
                container.appendChild(loader);
            }
                // Create and load script after 1 second
                setTimeout(() => {
                    if(container) {
                        container.remove();
                    }
                    const script = document.createElement('script');
                    script.src = `/call-widget-script.js?v=${Date.now()}`;
                    script.async = true;
                    script.type = 'text/javascript';
                    
                    const params = {
                        agentType: callNowAgent.name,
                        subscriptionId: callNowAgent.subscriptionId,
                        width: "350px",
                        height: "360px",
                        background: "#000000",
                        foreground: "#FFFFFF",
                        instanceId: Date.now()
                    };
                    
                    const queryString = new URLSearchParams(params).toString();
                    script.src = `${script.src}&${queryString}`;
                    script.setAttribute('data-widget-instance', 'true');
                    script.setAttribute('data-widget-script', 'true'); 

                    // Add error handling for script loading
                    script.onerror = (error) => {
                        console.error('Error loading widget script:', error);
                        cleanupWidget();
                        // Show error message
                        container.innerHTML = `
                            <div style="
                                color: white;
                                text-align: center;
                                padding: 20px;
                            ">
                                Failed to load voice assistant. Please try again.
                            </div>
                        `;
                    };
                    script.onloadstart = () => {
                        if(container) {
                            container.remove();
                        }
                    };

                    document.body.appendChild(script);
                }, timeOut); // 2 second delay
            
        }

        // Cleanup on unmount or when callNowAgent changes
        return () => {
            cleanupWidget();
        };
    }, [callNowAgent, user]);

    return (
        <div className={`home-page ${callNowAgent?.state}`}>
            <Header user={user} handleSession={handleSession} />
            <div className="banner-wrapper">
                <BannerRotation />
            </div>
            <div className="card-container">
                {renderCards}
            </div>
            <Footer />
            {/* <div id="chat-widget-container" style={{ position: 'fixed', bottom: '20px', right: '20px' }}></div> */}

            {showLogin && (
                <div className="login-modal">
                    <div className="login-container">
                        <X className="close-icon" onClick={closeLoginModal} />
                        <h3>Please log in to continue</h3>
                        <GoogleLogin 
                            onSuccess={handleGoogleLoginSuccess}
                            onError={handleGoogleLoginFailure}
                            text="Sign in with Google"
                        />
                    </div>
                </div>
            )}
        </div>
    );
});

export default Home;

import { useState, useCallback, useRef, useEffect } from 'react';
import log from '../utils/logger';
import { trimAudioBuffer, calculateRelativeTimes, convertToAudioBuffer } from '../utils/audioUtils'
import { useVADConfig, implementCustomVAD } from '../utils/vadUtils';

const useAudioRecording = (isAudioActive, setError, state) => {
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isMicOn, setIsMicOn] = useState(true);  // New state for mic status

  // Initialize refs
  const mediaRecorderRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const vadInstanceRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const sourceRef = useRef(null);
  const bufferRecorderRef = useRef(null);
  const audioBufferRef = useRef([]);
  const isBufferingRef = useRef(false);
  const isSetupAudioRecordingComplete = useRef(false);
  const prevMicStateRef = useRef(true);
  const callStateRef = useRef(state);
  const isAudioActiveRef = useRef(false);
  const gainNodeRef = useRef(null);
  const recordingStartTimeRef = useRef(null);

  const CHUNK_DURATION_MS = 1000;   // Duration of each audio chunk in milliseconds
  const MAX_BUFFER_CHUNKS = 150;    // Number of chunks to store (150 * 100ms = 15 seconds)
  //const PRE_VOICE_DURATION_MS = 1000; // 2 seconds before voice detection

  // Update refs when props change
	useEffect(() => {
    callStateRef.current = state;
	}, [state]);

  /**
   * Utility function to convert Blob to ArrayBuffer
   * @param {Blob} blob
   * @returns {Promise<ArrayBuffer>}
   */

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Function to create and connect gain node
  const createGainNode = useCallback(() => {
    if (!audioContextRef.current) {
      log.warn('@createGainNode AudioContext not initialized');
      return;
    }
    gainNodeRef.current = audioContextRef.current.createGain();
    gainNodeRef.current.gain.setValueAtTime(1, audioContextRef.current.currentTime);
  }, []);

  // Function to adjust bot volume
  const adjustBotVolume = useCallback((isRecording) => {
    if (!gainNodeRef.current || !audioContextRef.current) {
      log.warn('@adjustBotVolume Gain node or Audio Context not initialized');
      return;
    }
    const volume = isRecording ? 0.2 : 1; // Reduce to 20% when recording, otherwise full volume
    gainNodeRef.current.gain.setValueAtTime(volume, audioContextRef.current.currentTime);
  }, []);

  // Initialize VAD
  const initializeVAD = useCallback((stream) => {
    if (mediaStreamRef.current && !vadInstanceRef.current) {
      log.info("Initializing VAD...");
      try {
        vadInstanceRef.current = implementCustomVAD(
          mediaStreamRef.current,
          debounce(() => {
            if (!prevMicStateRef.current || !isAudioActiveRef.current) return;
            
            if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'inactive') {
              adjustBotVolume(true);
              mediaRecorderRef.current.start();
              setIsRecording(true);
              log.info('Recording started, state updated');
            }
          }, 100),
          debounce(() => {
            if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
              adjustBotVolume(false);
              mediaRecorderRef.current.stop();
              setIsRecording(false);
              log.info('Recording stopped, state updated');

              setTimeout(() => {
                bufferRecorderRef.current.start(CHUNK_DURATION_MS);
                setIsListening(true);
                initializeVAD(stream);
                log.info('setTimeout, setIsListening state updated');
              }, useVADConfig.cooldownPeriod);
            }

            if (bufferRecorderRef.current && bufferRecorderRef.current.state === 'recording') {
              bufferRecorderRef.current.stop();
            }
          }, 300),
          setDebugStats, // Pass setDebugStats to implementCustomVAD
          recordingStartTimeRef
        );
        log.debug('VAD initialized');
      } catch (error) {
        log.error('Error initializing VAD:', error);
        console.error('VAD initialization error details:', error);
      }
    }
  }, [adjustBotVolume]);

  const initializeAudioContext = useCallback(async () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      await audioContextRef.current.resume();
      log.debug('AudioContext initialized and resumed:', audioContextRef.current);
    } else if (audioContextRef.current.state === 'suspended') {
      await audioContextRef.current.resume();
      log.debug('AudioContext resumed');
    }
  }, [audioContextRef]);

  // Function to get supported MIME type
  const getSupportedMimeType = useCallback(() => {
    const mimeTypes = [
      'audio/webm; codecs=opus',
      'audio/webm',
      'audio/ogg; codecs=opus',
      'audio/ogg',
    ];

    return mimeTypes.find(mimeType => MediaRecorder.isTypeSupported(mimeType)) || '';
  }, []);

  // Function to initialize Media Recorder with supported MIME type
  const initializeMediaRecorder = useCallback((stream) => {
    const mimeType = getSupportedMimeType();
    if (!mimeType) {
      throw new Error('No supported MIME type found for Media Recorder.');
    }

    const options = { mimeType };
    const recorder = new MediaRecorder(stream, options);
    return recorder;
  }, [getSupportedMimeType]);

  // Stop Buffering Function
  const stopBuffering = useCallback(() => {
    if (!isBufferingRef.current) return;
    isBufferingRef.current = false;
    const bufferRecorder = bufferRecorderRef.current;
    if (bufferRecorder && bufferRecorder.state !== 'inactive') {
      bufferRecorder.stop();
      //bufferRecorderRef.current = null;
    }
  }, []);

  // Start buffering function
  const startBuffering = useCallback((stream) => {
    if (isBufferingRef.current) return;
    isBufferingRef.current = true;

    const bufferRecorder = initializeMediaRecorder(stream);
    bufferRecorderRef.current = bufferRecorder;

    bufferRecorder.ondataavailable = (event) => {
      if (audioBufferRef.current.length === 0) {
        log.info('Buffering started!!!');
      }
      if (event.data.size > 0) {
        //log.info('Buffering in progress...');
        const chunkTimestamp = Date.now(); // Timestamp when chunk is received
        audioBufferRef.current.push({ blob: event.data, timestamp: chunkTimestamp });
        
        if (audioBufferRef.current.length > MAX_BUFFER_CHUNKS) { // Keep last 15 seconds (150 chunks * 100ms)
          audioBufferRef.current.shift(); // Remove oldest chunk
        }
      }
    };

    bufferRecorder.onstop = async () => {
      log.info('Buffering stopped!!!');
      if (callStateRef.current !== 'idle' && audioContextRef.current) {
        // Ensure recordingStartTimeRef.current is not negative
        const effectiveStartTime = recordingStartTimeRef.current > 0 ? recordingStartTimeRef.current : 0;
        
        const bufferedChunks = audioBufferRef.current; //.slice(-10);
        log.debug(`Buffered Chunks Count: ${bufferedChunks.length}`);

        if (bufferedChunks.length === 0) {
          console.warn('No audio chunks found after the detected start time.');
          return;
        }

        const bufferStartTime = bufferedChunks[0].timestamp;
        log.debug(`bufferStartTime: ${bufferStartTime}`);

        // Get the timestamp of the last chunk
        const lastBufferedChunk = bufferedChunks.slice(-1)[0];
        const bufferEndTime = lastBufferedChunk ? lastBufferedChunk.timestamp : null;

        if (!audioContextRef.current) {
          log.info('AudioContext is not initialized');
          return;
        }

        const audioBuffer = await convertToAudioBuffer(bufferedChunks, audioContextRef)

        // Calculate relative times
        const { relativeStartTime, relativeEndTime } = calculateRelativeTimes(bufferStartTime, effectiveStartTime, bufferEndTime);

        // Trim silence based on voice detection timestamps
        const trimmedWavBlob = trimAudioBuffer(audioContextRef, audioBuffer, relativeStartTime, relativeEndTime);

        setAudioChunks([trimmedWavBlob]);
      }

      audioBufferRef.current = [];
      isBufferingRef.current = false;
    };

    bufferRecorder.start(CHUNK_DURATION_MS); // Record in 1000ms chunks
    log.info('Buffering started!');

  }, [initializeMediaRecorder]);

  // Utility function to initialize media recorder and audio context
  const setupAudioRecording = useCallback(async (micStateOn) => {

    if (callStateRef.current === 'idle') {
      //log.info('State is idle, not setting up audio recording');
      return false; // Return false to indicate setup was not performed
    }

    if (isSetupAudioRecordingComplete.current) {
      log.info('Audio recording already set up');
      return;
    }

    if (mediaRecorderRef.current) {
      log.info('MediaRecorder already set up');
      return;
    }

    try {
      log.info('Setting up audio recording called');
      
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          noiseSuppression: true,
          echoCancellation: true,
        },
      });
      //log.info('User media obtained with noise suppression');
      mediaStreamRef.current = stream;
      // Start buffering
      await startBuffering(stream);

      // Set initial state of audio tracks based on isMicOn
      stream.getAudioTracks().forEach(track => {
        track.enabled = micStateOn && isAudioActive ? true : false;
      });

      // Initialize or resume AudioContext
      await initializeAudioContext()

      createGainNode();

      // Initialize MediaRecorder
      const recorder = initializeMediaRecorder(stream);
      // recorder.ondataavailable = (event) => {
      //   if (event.data.size > 0) {
          
      //   }
      // };

      recorder.onstop = () => {
        setIsRecording(false);
        // Reinitialize VAD after stopping
        initializeVAD(stream);
      };

      mediaRecorderRef.current = recorder;

      // Initialize Analyser and MediaStreamSource
      if (audioContextRef.current && audioContextRef.current.state === 'running') {
        if (!analyserRef.current) {
          analyserRef.current = audioContextRef.current.createAnalyser();
          analyserRef.current.fftSize = 2048;
          //log.debug('Analyser node created');
        }

        if (sourceRef.current) {
          sourceRef.current.disconnect();
        }

        sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
        sourceRef.current.connect(analyserRef.current);
        //log.debug('MediaStreamSource connected to Analyser');
      } else {
        log.warn('Audio context not ready');
      }

      // Initialize VAD only if mic is on
      if (micStateOn) {
        initializeVAD(stream);
      }
      isSetupAudioRecordingComplete.current = true;
    } catch (error) {
      log.error('Error setting up audio recording:', error);
      log.error('Detailed error:', error);
      log.log('AudioContext:', audioContextRef.current);
      log.log('Stream:', mediaStreamRef.current);

      if (setError) setError(`Error setting up audio recording: ${error.message}`);
    }
    return true; // Return true to indicate successful setup
  }, [setError, initializeVAD, startBuffering, initializeAudioContext, isAudioActive, createGainNode, initializeMediaRecorder]);

  const startListening = useCallback(async(micStateOn) => {
    if (isListening || !micStateOn || !isAudioActive) {
      log.info('Already listening or mic is off, not starting to listen');
      return;
    }
    
    try {
      if (!analyserRef.current || !vadInstanceRef.current) {
        log.info('Audio system not fully initialized, attempting to set up');
        const setupSuccess = await setupAudioRecording(true);
        if (!setupSuccess) {
          log.error('Failed to set up audio recording');
          return;
        }
      }

      if (!analyserRef.current || !vadInstanceRef.current) {
        log.error('Audio system still not fully initialized after reinitialization attempt');
        return;
      }
    
      setIsListening(true);
      adjustBotVolume(true);

      if (vadInstanceRef.current.reset) {
        vadInstanceRef.current.reset();
      }
    
      log.info('Started listening for voice activity');
    } catch (error) {
      log.error('Error in startListening:', error);
      setError(`Error starting to listen: ${error.message}`);
    }
  }, [analyserRef, vadInstanceRef, isListening, setupAudioRecording, isAudioActive, setError, adjustBotVolume]);

  // Stop and cleanup recording
  const stopAndCleanupRecording = useCallback(() => {

    if (callStateRef.current === 'idle') {
      log.info('State is idle, not cleaning up audio recording');
      return;
    }

    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      //setIsRecording(false);
      log.info('Recording stopped, state updated');
    }

    // Reset buffer recorder
    stopBuffering();

    isSetupAudioRecordingComplete.current = false;

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => {
        track.stop();
        track.enabled = false;
      });
      mediaStreamRef.current = null;
    }

    if (sourceRef.current) {
      sourceRef.current.disconnect();
      sourceRef.current = null;
    }

    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close().then(() => {
        log.debug('AudioContext closed');
        audioContextRef.current = null;
      });
    }

    if (vadInstanceRef.current) {
      vadInstanceRef.current.stop();
      vadInstanceRef.current = null;
    }

    if (analyserRef.current) {
      analyserRef.current.disconnect();
      analyserRef.current = null;
    }
    mediaRecorderRef.current = null;
    setIsRecording(false);
    setIsListening(false);
    adjustBotVolume(false);
  }, [adjustBotVolume, stopBuffering]);

  useEffect(() => {
    if (callStateRef.current === 'idle' || isMicOn === prevMicStateRef.current) {
      return; // No change in mic state, do nothing
    }

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getAudioTracks().forEach(track => {
        track.enabled = isMicOn;
      });
      log.info(`Mic ${isMicOn ? 'enabled' : 'muted'}`);
    } else {
      log.warn('No media stream available to toggle mic state');
    }

    if (isMicOn && !isSetupAudioRecordingComplete.current) {
      // Only setup and start listening if not already set up
      setupAudioRecording(true).then(() => {
        startListening(true);
      });
    }

    prevMicStateRef.current = isMicOn;
  }, [isMicOn, setupAudioRecording, startListening]);

  // Log recording state changes
  useEffect(() => {
    log.info(`Recording state changed: ${isRecording ? 'active' : 'inactive'}`);
    // if (gainNodeRef.current) {
    //   log.info('Current gain value:', gainNodeRef.current.gain.value);
    // }
  }, [isRecording]);

  // Update refs when props change
	useEffect(() => {
    if (isAudioActive !== isAudioActiveRef.current) {
      //log.debug('isAudioActive: ', isAudioActive);
      isAudioActiveRef.current = isAudioActive;
      if(isAudioActive){
        try {
          setupAudioRecording(true).then((setupSuccess) => {
            if (setupSuccess) {
              startListening(true);
            } else {
              log.warn('Audio recording setup failed, not starting listening');
            }
          });
        } catch (error) {
          log.error('Error during audio recording setup:', error);
          setError(`Error setting up audio recording: ${error.message}`);
        }
      }
    }
	}, [isAudioActive, setupAudioRecording, startListening, setError]);

  const [debugStats, setDebugStats] = useState(null);
  const getGainNode = useCallback(() => gainNodeRef.current, []);

  return {
    setupAudioRecording,
    stopAndCleanupRecording,
    audioChunks,
    analyserRef,
    audioContextRef,
    mediaRecorderRef,
    mediaStreamRef,
    sourceRef,
    startListening,
    isListening,
    isRecording,
    setIsListening,
    setIsRecording,
    isSetupAudioRecordingComplete,
    debugStats,
    debugMode: useVADConfig.debugMode,
    setIsMicOn,  // handle mic state
    isMicOn,  // New state exposed
    getGainNode,
    setAudioChunks
  };
};

export default useAudioRecording;
import React, { useState, useEffect, useRef } from 'react';
import UserAvatar from './UserAvatar';
import TalkingWaveAnimation from './TalkingWaveAnimation';
import { Play, Square, Mic, MicOff } from 'lucide-react';
import MessagePool from '../../utils/MessagePool';

const UserSide = ({
  isUserTalking,
  waveData,
  state,
  user,
  handleRecording,
  handleEndTalking,
  isRecording,
  handleUserMic,
  isMicOn,
  currentUserMessage,
  chatMode,
  setChatMode,
}) => {
  const [speakPrompt, setSpeakPrompt] = useState('');
  const messagePoolRef = useRef(null);

  useEffect(() => {
    const speakPrompts = [
      "Speak to send a voice message 🎤",
      "Ready to record your message? 🔴",
      "Your turn to speak!!! 🗣️",
      "Mic is on, start talking... 🎙️",
      "Voice message ready, speak now 📢",
      "Go ahead, we're listening!!! 👂",
      "Time to record your message... ⏺️",
      "Speak your mind 💭",
      "Your voice, our ears 🦻",
      "Message when ready 😊"
    ];
    messagePoolRef.current = new MessagePool(speakPrompts, 6); // Use default or adjust threshold as needed
    updateSpeakPrompt();
  }, []);

  const updateSpeakPrompt = () => {
    if (messagePoolRef.current) {
      setSpeakPrompt(messagePoolRef.current.getMessage());
    }
  };

  // Call updateSpeakPrompt when relevant state changes
  useEffect(() => {
    updateSpeakPrompt();
  }, [state, isMicOn, isUserTalking, isRecording]);

  return (
    <div className="side-container user-side">
      <div className="chat-bubble user-bubble-container d-flex">
        <div className="user-content-wrapper">
          <div className="d-flex user-controls">
            {state !== 'idle' && (
              <div className="user-mic-container">
                <div
                  className={`d-flex-c user-mic-button talk-button ${isMicOn ? 'mic-on' : 'mic-off'}`}
                  title={isMicOn ? 'Mute microphone' : 'Unmute microphone'}
                  onClick={() => handleUserMic(!isMicOn)}
                >
                  {isMicOn ? <Mic size={18} /> : <MicOff size={18} />}
                </div>
                <div className="status-text txt-uc" style={{ fontSize: '0.65rem' }}>
                  {isMicOn ? 'Mute' : 'Unmute'}
                </div>
              </div>
            )}
            <div className="talk-button-container w-auto">
              <button
                className="talk-button"
                style={{ backgroundColor: state === 'idle' ? '#219654' : '#ef4444' }}
                onClick={state === 'idle' ? handleRecording : handleEndTalking}
                title={state === 'idle' ? 'Start Call' : 'End Call'}
              >
                {state === 'idle' ? <Play size={16} /> : <Square size={12} />}
              </button>
              <div className="status-text txt-uc" style={{ fontSize: '0.65rem' }}>
                {state === 'idle' ? 'Start' : 'End'}
              </div>
            </div>
          </div>
          <div className="d-flex w-50">
            {state === 'idle' ? (
              <div className="start-prompt-message">Hit 'START' to begin!</div>
            ) : isMicOn ? (
              isUserTalking || isRecording ? (
                <div className="user-talking-wave">
                  <TalkingWaveAnimation type={'user'} waveData={waveData} />
                </div>
              ) : (
                <div className="speak-prompt-message">{speakPrompt}</div>
              )
            ) : (
              <div className="muted-message">Your mic is muted!</div>
            )}
          </div>
        </div>
        <div className="bot-message-container">
          {currentUserMessage && <p className="bot-message">{currentUserMessage}</p>}
        </div>
        <div className="corner-bottom-right"></div>
      </div>
      <div className="user-avatar-container">
        <UserAvatar />
        <div className="w-100 status-text txt-uc d-flex-c">
          YOU
        </div>
      </div>
    </div>
  );
};

export default UserSide;

import React, { useState, useCallback, useEffect } from 'react';
import { LogOut } from 'lucide-react';
import './UserDropdown.css';

const UserDropdown = ({ user, onLogout }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = useCallback((e) => {
    e.stopPropagation();
    setShowDropdown(prev => !prev);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.user-dropdown-container')) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className="user-dropdown-container">
      <span 
        className="d-flex-c user-icon" 
        onClick={toggleDropdown}
        title={user.name}
      >
        <img
          src={user.picture}
          alt={user.name}
          className="user-image"
          style={{ width: 'auto', height: '18px' }}
        />
      </span>
      
      {showDropdown && (
        <div className="user-dropdown">
          <div className="user-dropdown-header">
            <img
              src={user.picture}
              alt={user.name}
              className="user-dropdown-image"
            />
            <div className="user-dropdown-info">
              <div className="user-dropdown-name">{user.name}</div>
              <div className="user-dropdown-email">{user.email}</div>
            </div>
          </div>
          <div className="d-flex-c" style={{fontSize: '10px'}}>{user.id}</div>
          <div className="user-dropdown-divider" />
          <button 
            className="user-dropdown-item"
            onClick={onLogout}
          >
            <LogOut size={16} />
            <span>Logout</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;

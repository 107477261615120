import log from './logger';

/**
 * Initializes a WebSocket connection with automatic reconnection logic.
 * 
 * @param {Object} params - The parameters for WebSocket initialization.
 * @param {Function} params.onMessage - Callback for handling messages.
 * @param {Function} params.onError - Callback for handling errors.
 * @param {Function} params.onOpen - Callback for successful connection.
 * @param {Function} params.onClose - Callback for connection closure.
 * @param {Object} params.isUnmounted - A ref to check if the component is unmounted.
 * @param {string} [params.url] - Optional WebSocket URL to connect to.
 * @returns {WebSocket} The initialized WebSocket object.
 */
export const initializeWebSocket = ({
  onMessage,
  onError,
  onOpen,
  onClose,
  isUnmounted,
  url,
}) => {
  console.log('initializeWebSocket called', { url });

  const isLocalhost = window.location.hostname === 'localhost';
  const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const wsServerUrl = url || (isLocalhost 
    ? `${wsProtocol}//localhost`
    : `${wsProtocol}//call.rapo.ai`);

  console.log(`Attempting to connect to WebSocket at: ${wsServerUrl}`);

  const ws = new WebSocket(wsServerUrl);

  ws.onopen = () => {
    console.log('WebSocket onopen triggered');
    if (isUnmounted?.current) {
      console.log('Component unmounted, skipping onopen');
      return;
    }
    if (onOpen) onOpen();
  };

  ws.onmessage = (event) => {
    // console.log('WebSocket message received', event.data);
    if (isUnmounted?.current) {
      console.log('Component unmounted, skipping onmessage');
      return;
    }
    if (event.data instanceof Blob) {
      onMessage(event.data);
    } else {
      try {
        const parsedData = JSON.parse(event.data);
        onMessage(parsedData);
      } catch (error) {
        log.error('Error parsing WebSocket message:', error);
        if (onError) onError('Failed to parse server message');
      }
    }
  };

  ws.onerror = (error) => {
    log.error('WebSocket error in utility:', error);
    if (isUnmounted?.current) {
      console.log('Component unmounted, skipping onerror');
      return;
    }
    if (onError) onError('WebSocket connection error');
  };

  ws.onclose = (event) => {
    console.log(`WebSocket onclose triggered (code: ${event.code}, reason: ${event.reason || 'No reason provided'})`);
    if (isUnmounted?.current) {
      console.log('Component unmounted, skipping onclose');
      return;
    }
    if (onClose) onClose(event);
  };

  return ws;
};
import React, { useEffect } from 'react';

const TestWidgetScript = () => {
    useEffect(() => {
        // Check if the script is already present
        const existingScript = document.getElementById('call-widget-script');
        if (!existingScript) {
            // Create a script element
            const script = document.createElement('script');
            script.id = 'call-widget-script'; // Set an ID to identify the script
            script.src = "/call-widget-script.js?agentType=exampleAgent&subscriptionId=12345&theme=dark"; // Update the script source with parameters
            script.async = true; // Load the script asynchronously

            // Append the script to the document body
            document.body.appendChild(script);
        }

        // Cleanup function to remove the script when the component unmounts
        return () => {
            const scriptToRemove = document.getElementById('call-widget-script');
            if (scriptToRemove) {
                document.body.removeChild(scriptToRemove);
            }
        };
    }, []);

    return (
        <div>
            <h1>Test Widget Script</h1>
            <p>The chat widget script has been loaded.</p>
        </div>
    );
};

export default TestWidgetScript;
